/* Fonts */

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Nunito:wght@300&display=swap');

* {
  margin: 0;
  padding: 0;
}

.App {
  min-height: 100vh;
  display: flex;
  /* background-image: url("./Assets/background.jpg");
  background-size: cover;
  background-position: center; */
  background-image: linear-gradient(to right top, #051937, #171228, #190a1a, #12040d, #000000);
}

#forgotPass:hover {
  color: purple;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.281);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.typing {
  width: 5em;
  height: 2em;
  position: relative;
  padding: 10px;
  margin-left: 5px;
  background: #e6e6e6;
  border-radius: 20px;
}

.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

.btn:hover {
  color: purple;
}

.chatBox {
  background-image: linear-gradient(to right top, #051937, #171228, #190a1a, #12040d, #000000);
}

#userItem {
  background: gray.200;
  opacity: 0.6
}

#userItem:hover {
  cursor: not-allowed;
  pointer-events: none;
}